import React, { Component } from 'react';
// import selectC from "../../images/selectC.svg";

class SelectClass extends Component {
    constructor(){
        super();
        this.state = {
            successMsg:false
        }
    }

    setSuccess = () => {
        this.setState({successMsg:!this.state.successMsg},()=>{
            if(this.state.successMsg){
                let payload = {
                    name:localStorage.getItem("NAME"),
                    userId:localStorage.getItem("USERID"),
                    number:localStorage.getItem("NUMBER"),
                    orgId:localStorage.getItem("ORGID"),
                    email:localStorage.getItem("EMAIL"),
                    grade:localStorage.getItem("SELECTCLASS"),
                }

                fetch(`https://hooks.zapier.com/hooks/catch/4460305/ocht4vv/`, {
                    method: "POST",
                    body: JSON.stringify(payload),
                  })
                    .then((res) => res.json())
                    .then((res) => {
                        console.log(res.message)
                    })
                    .catch((err) => console.log(err.message));
            }
        })
    }

    render(){
        const {
            selectClass,
            classData,
            BgColor1,
            BgColor2,
            color1,
            color2
        } = this.props;
        const {successMsg} = this.state;
        return (
            <div className="SelectClass">
                <div className="SelectClass__Container">
                    <div className="SelectClass__Container--Header">
                        <img src="https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/Dec20/selectC.svg" alt="select" className="SelectClass__Container--Header__Img" />
                    </div>
                    {!successMsg ?
                        <div className="SelectClass__Container--Body">
                            <div className="SelectClass__Container--Body__Para1">Welcome to Kids Coding Week!</div>
                            <div className="SelectClass__Container--Body__Para2">Select your class to get started</div>
                            <div className="SelectClass__Container--Body__BtnDiv">
                                {classData.map((data,index)=>{
                                    return(
                                        <button 
                                            style={index==1?
                                                {
                                                    marginLeft:"0.8rem",
                                                    background:BgColor2,
                                                    color:color2,
                                                }:
                                                {
                                                    background:BgColor1,
                                                    color:color1,
                                                }
                                            }
                                            key={index} 
                                            className="SelectClass__Container--Body__BtnDiv--Btn"
                                            onClick={()=>{this.props.setClass(index,data.btn)}}
                                        >
                                            {data.btn}
                                        </button>
                                    )
                                })}
                            </div>
                        </div>
                    : 
                        <div className="SelectClass__Container--Body">
                            <div className="SelectClass__Container--Body__Para1">Welcome to Kids Coding Week!</div>
                            <div className="SelectClass__Container--Body__Para2">You selected Class {selectClass} as your category. This can not be changed later.</div>
                            <div onClick={this.setSuccess} className="SelectClass__Container--Body__Oops">Oops, take me back</div>
                            <div className="SelectClass__Container--Body__Para2">Explore a variety of contests, attend free classes and win exciting prizes. Learn coding the fun way!</div>
                        </div>
                    }
                    <div className="SelectClass__Container--Footer">
                        <button
                            disabled={selectClass === "" ? true:false} 
                            className="SelectClass__Container--Footer__Btn"
                            onClick={()=>
                                {
                                    successMsg ? this.props.openModal() : this.setSuccess()
                                }
                            }
                            style={selectClass !== "" ?{background:"#2C7478"}:{background:"#e5e5e5"}}
                        >{!successMsg ? "Continue" : "Let’s start!"}</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default SelectClass;