import React, { Component } from "react";
import KidsCodingWeek from "./KidsCodingWeek";
import RegisterModal from "./MiniComponents/RegisterModal";
import SelectClass from "./MiniComponents/SelectClass";
import UpComingModal from "./MiniComponents/UpComingModal";
//import QuizDay from './QuizDay'
import ProjectDay from "./ProjectDay";

import UpcomingEvents from "./UpcomingEvents";
import Awards from "./Awards";
import PreviousWinner from "./PreviousWinner";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      isSenior: false,
      classData: [
        {
          btn: "Class 6 - Class 8",
          // date:"19 Dec: 11 am - 12 pm",
          date2: "20 Dec: 5 pm - 6 pm",
          dateNew: "20 Dec: 11 am - 12 pm",
        },
        {
          btn: "Class 9 - Class 12",
          // date:"19 Dec: 5 pm - 6 pm",
        },
      ],
      BgColor1: "",
      BgColor2: "",
      color1: "",
      color2: "",
      selectClass: "",
      classSelected: false,
      registerClicked: false,
      regDone: false,
      upClicked: false,
      seletedArray: "",
      isUpTrue: true,
      freeClass: {
        image:
          "https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/Dec20/gameBg.svg",
        heading: "Free Coding Classses",
        data1: {
          head: "Learn to create a game",
          para:
            "Attend the free coding class and learn to create a game! Take a quiz at the end of the coding class and win exciting prizes.",
        },
        data2: {
          head: "Limited slots available",
          timeSlot: [
            {
              date1: "19 Dec : ",
              date2: "20 Dec : ",
              time1: "11 am - 12 pm",
              time2: "5 pm - 6 pm",
            },
            {
              date1: "19 Dec : ",
              date2: "20 Dec : ",
              time1: "5 pm - 6 pm",
              time2: "11 am - 12 pm",
            },
          ],
        },
        data3: {
          head: "Prizes to be won",
          points: [
            "Samsung Tablet",
            "Amazon Vouchers worth ₹500",
            "Coding Courses",
            "Assured Certificates to all participants",
          ],
        },
      },
      createClass: {
        image:
          "https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/Dec20/codeBg.svg",
        heading: "Code and create innovative projects",
        data1: {
          head: "What to do",
          para:
            "Put on your thinking caps to explore coding to bring out something new and innovative. Let your creativity run wild. ",
        },
        data2: {
          head: "Event timing",
          para: "Submit your projects starting from 21 Dec’20 to 23 Dec’ 20",
        },
        data3: {
          head: "Prizes to be won",
          points: [
            "Samsung Tablet",
            "Amazon Vouchers worth ₹500",
            "JBL Earphones",
            "Coding Courses",
            "Assured Certificates to all participants",
          ],
        },
      },
      quizClass: {
        image:
          "https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/Dec20/quizBg.svg",
        heading: "Code and create innovative projects",
        data1: {
          head: "What to do",
          para: "Attempt this quiz and evaluate your skills!",
        },
        data2: {
          head: "Event timing",
          para: "Quiz wizard will take place on 22 Dec’20",
        },
        data3: {
          head: "Prizes to be won",
          points: [
            "Samsung Tablet",
            "Amazon Vouchers worth ₹500",
            "JBL Earphones",
            "Coding Courses",
            "Assured Certificates to all participants",
          ],
        },
      },
    };
  }

  componentDidMount() {}

  openRegister = (reg) => {
    this.setState({ registerClicked: !this.state.registerClicked }, () => {
      if (reg === "register") {
        this.setState({ regDone: true }, () => {
          localStorage.setItem("REGDONE", this.state.regDone);
        });
      }
    });
  };

  setClass = (index, data) => {
    if (index === 0) {
      this.setState(
        {
          BgColor1: "#2C7478",
          BgColor2: "#FFFFFF",
          color1: "#FFFFFF",
          color2: "#2C7478",
          selectClass: data,
        },
        () => {
          localStorage.setItem("BGCOLOR1", this.state.BgColor1);
          localStorage.setItem("BGCOLOR2", this.state.BgColor2);
          localStorage.setItem("COLOR1", this.state.color1);
          localStorage.setItem("COLOR2", this.state.color2);
          localStorage.setItem("SELECTCLASS", this.state.selectClass);
        }
      );
    } else {
      this.setState(
        {
          BgColor2: "#2C7478",
          BgColor1: "#FFFFFF",
          color2: "#FFFFFF",
          color1: "#2C7478",
          selectClass: data,
        },
        () => {
          localStorage.setItem("BGCOLOR1", this.state.BgColor1);
          localStorage.setItem("BGCOLOR2", this.state.BgColor2);
          localStorage.setItem("COLOR1", this.state.color1);
          localStorage.setItem("COLOR2", this.state.color2);
          localStorage.setItem("SELECTCLASS", this.state.selectClass);
        }
      );
    }
  };

  openUpModal = (dt) => {
    this.setState({ upClicked: !this.state.upClicked, seletedArray: dt });
  };

  isUp = () => {
    this.setState({ isUpTrue: true });
  };

  render() {
    const {
      selectClass,
      classData,
      BgColor1,
      BgColor2,
      color1,
      color2,
      registerClicked,
      regDone,
      upClicked,
      seletedArray,
      freeClass,
      createClass,
      quizClass,
    } = this.state;
    const { classSelected, openModal } = this.props;
    const SELECTCLASS = localStorage.getItem("SELECTCLASS");
    const BGCOLOR1 = localStorage.getItem("BGCOLOR1");
    const BGCOLOR2 = localStorage.getItem("BGCOLOR2");
    const COLOR1 = localStorage.getItem("COLOR1");
    const COLOR2 = localStorage.getItem("COLOR2");
    const REGDONE = localStorage.getItem("REGDONE");
    return (
      <div className="Home">
        <KidsCodingWeek />
        {/* <RegisterNow 
                    openRegister={this.openRegister} 
                    regDone={regDone || REGDONE} 
                /> */}
        {/* <QuizDay /> */}
        <ProjectDay />
        {
           <PreviousWinner />
        }
        <UpcomingEvents openUpModal={this.openUpModal} isUp={this.isUp} />
        <Awards />
        {classSelected ? (
          <SelectClass
            classData={classData}
            selectClass={selectClass || SELECTCLASS}
            BgColor1={BgColor1 || BGCOLOR1}
            BgColor2={BgColor2 || BGCOLOR2}
            color1={color1 || COLOR1}
            color2={color2 || COLOR2}
            setClass={this.setClass}
            openModal={openModal}
          />
        ) : (
          ""
        )}
        {registerClicked ? (
          <RegisterModal
            classData={classData}
            selectClass={selectClass || SELECTCLASS}
            BgColor1={BgColor1 || BGCOLOR1}
            BgColor2={BgColor2 || BGCOLOR2}
            color1={color1 || COLOR1}
            color2={color2 || COLOR2}
            openModal={this.openRegister}
          />
        ) : (
          ""
        )}
        {upClicked ? (
          <UpComingModal
            dataArray={
              seletedArray === 0
                ? freeClass
                : seletedArray === 1
                ? createClass
                : quizClass
            }
            selectClass={selectClass}
            seletedArray={seletedArray}
            openUpModal={this.openUpModal}
            openRegister={this.openRegister}
            regDone={regDone || REGDONE}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Home;
