import React from 'react';
import { Component } from 'react';
import prize1 from "../../images/prize1.png";
import prize2 from "../../images/prize2.png";
import prize3 from "../../images/prize3.png";
import prize4 from "../../images/prize4.png";
import prize5 from "../../images/prize5.png";

class PrizeComponent extends Component {
    render(){
        const {label,prize,rank} = this.props;
        return(
            <div className="PrizeComponent">
                <div className="PrizeComponent__Col1">
                    <div className="PrizeComponent__Col1--Para1">{label}</div>
                    <div className="PrizeComponent__Col1--Para2">{prize}</div>
                </div>
                <div className="PrizeComponent__Col2">
                    <img src={rank === 1 ? prize1 :
                                rank === 2 ? prize3 : prize4 
                             } 
                        alt="prize" className="PrizeComponent__Col2--Img" />
                </div>
            </div>
        )
    }
}

export default PrizeComponent;