import React, { Component } from 'react';

class EventComponent extends Component {
    render(){
        const {data,id} = this.props;
        return (
            <div className="EventComponent"
            onClick={()=>{
                this.props.openUpModal(id)
            }} 
            >
                <div className="EventComponent__Col1">
                    <img src={data.image} alt="icon" />
                </div>
                <div className="EventComponent__Col2">
                    <div className="EventComponent__Col2--Par1">{data.para1}</div>
                    <div className="EventComponent__Col2--Par2">{data.para2}</div>
                </div>
                <div className="EventComponent__Col3">
                    <div className="EventComponent__Col3--Par1">{data.date}</div>
                    <div className="EventComponent__Col3--Par2">{data.month}</div>
                </div>
            </div>
        )
    }
}

export default EventComponent;