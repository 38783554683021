import React, { Component } from 'react';
import calendar from "../images/calendar.svg"

class KidsCodingWeek extends Component {
    render(){
        return (
            <div className="KidsCodingWeek">
                <div className="KidsCodingWeek__Para1">Kids Coding Week</div>
                <div className="KidsCodingWeek__Div">
                    <img src={calendar} alt="calender" className="KidsCodingWeek__Div--Img" />
                    <div className="KidsCodingWeek__Div--Para">December 17-23</div>
                </div>
                <div className="KidsCodingWeek__Para2">Learn the basics of coding, attend quizzes, create projects and win exciting prizes! </div>
            </div>
        )
    }
}

export default KidsCodingWeek;