import React, { Component } from 'react';
import close from "../../images/close.svg"

class RegisterModal extends Component {
    constructor(){
        super();
        this.state = {
            successMsg:false,
            BgColor1:"#2C7478",
            BgColor2:"#FFFFFF",
            color1:"#FFFFFF",
            color2:"#2C7478",
            selectTime:"",
            isSenior: false
        }
    }

    componentDidMount(){
        const {
            selectClass,
            classData,
        } = this.props;
        localStorage.setItem("SELECTTIME",selectClass === "Class 6 - Class 8" ? classData[0].date2 : classData[0].dateNew);
    }

    setSuccess = () => {
        this.setState({successMsg:!this.state.successMsg},()=>{
            if(this.state.successMsg){
                this.showCourseLink()
                let payload = {
                    name:localStorage.getItem("NAME"),
                    userId:localStorage.getItem("USERID"),
                    number:localStorage.getItem("NUMBER"),
                    orgId:localStorage.getItem("ORGID"),
                    email:localStorage.getItem("EMAIL"),
                    grade:localStorage.getItem("SELECTCLASS"),
                    slot:localStorage.getItem("SELECTTIME")
                }

                fetch(`https://hooks.zapier.com/hooks/catch/4460305/ochmo58/`, {
                    method: "POST",
                    body: JSON.stringify(payload),
                  })
                    .then((res) => res.json())
                    .then((res) => {
                        console.log(res.message)
                    })
                    .catch((err) => console.log(err.message));
            }
        })
    }

    setTimeSlot = (index,data) =>{
        console.log('index, data', index, data);
        if(index === 0) {
            this.setState({
                BgColor1:"#2C7478",
                BgColor2:"#FFFFFF",
                color1:"#FFFFFF",
                color2:"#2C7478",
                selectTime:data,
            },()=>{localStorage.setItem("SELECTTIME",this.state.selectTime)})
        }else{
            this.setState({
                BgColor2:"#2C7478",
                BgColor1:"#FFFFFF",
                color2:"#FFFFFF",
                color1:"#2C7478",
                selectTime:data,
            },()=>{localStorage.setItem("SELECTTIME",this.state.selectTime)})
        }
    }

    showCourseLink = () => {
        let selectedTime = localStorage.getItem("SELECTTIME");

        if (selectedTime.includes('11')){
            this.setState({
                isSenior: true
            })
        }
        
    }

    render(){
        const {
            selectClass,
            classData,
        } = this.props;
        const {
            BgColor1,
            BgColor2,
            color1,
            color2,
            successMsg
        } =this.state;
        const img1 = "https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/Dec20/gameBg.svg";
        const img2 = "https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/Dec20/checkBg.svg";
        return (
            <div className="RegisterModal">
                <div className="RegisterModal__Container">
                    <div className="RegisterModal__Container--Header">
                        <img src={!successMsg ? img1 : img2} alt="select" className="RegisterModal__Container--Header__Img" />
                        <img src={close} onClick={this.props.openModal} alt="close" className="RegisterModal__Container--Header__Close" />
                    </div>
                    {!successMsg ?
                        <div className="RegisterModal__Container--Body">
                            <div className="RegisterModal__Container--Body__Para1">Free Coding Classses</div>
                            <div className="RegisterModal__Container--Body__Para2">Select preferred time    </div>
                            <div className="RegisterModal__Container--Body__BtnDiv">
                                <button 
                                    style={{
                                            background:BgColor1,
                                            color:color1,
                                        }}
                                    onClick={()=>this.setTimeSlot(0,selectClass === "Class 6 - Class 8" ? classData[0].date2 : classData[0].dateNew)}
                                    className="RegisterModal__Container--Body__BtnDiv--Btn"
                                >
                                    {selectClass === "Class 6 - Class 8" ? classData[0].date2 : classData[0].dateNew}
                                </button>
                                <button 
                                    style={{
                                            marginLeft:"0.8rem",
                                            background:BgColor2,
                                            color:color2,
                                        }}
                                    onClick={()=>this.setTimeSlot(1,selectClass === "Class 6 - Class 8" ? classData[0].date2 : classData[1].date2)}
                                    className="RegisterModal__Container--Body__BtnDiv--Btn"
                                >
                                    {selectClass === "Class 6 - Class 8" ? classData[0].date2 : classData[1].date2 }
                                </button>
                            </div>
                        </div>
                    : 
                        <div className="RegisterModal__Container--Body">
                            <div className="RegisterModal__Container--Body__Para1">Registered successfully!</div>
                            <div className="RegisterModal__Container--Body__Para2">
                                You have registered for the free coding class. You can join the class on the following zoom link:-
                                <br/>
                                <br/>
                                        
                                {`${this.state.isSenior ? 'https://us02web.zoom.us/j/83321156268' : 'https://us02web.zoom.us/j/81735203575'}   
                                `}

                                <br/>
                                <br/>
                                     You will also receive the class link on your registered email id.
                                
                                
                                </div>
                        </div>
                    }
                    <div className="RegisterModal__Container--Footer">
                        <button
                            disabled={selectClass === "" ? true:false} 
                            className="RegisterModal__Container--Footer__Btn"
                            onClick={()=>
                                {
                                    successMsg ? this.props.openModal("register") : this.setSuccess()
                                }
                            }
                            style={selectClass !== "" ?{background:"#2C7478"}:{background:"#e5e5e5"}}
                        >{!successMsg ? "Register for free class" : "Okay!"}</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default RegisterModal;