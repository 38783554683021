import React, { Component } from "react";
import "./CustomUser.css";
class CustomUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameInitials: "",
      colors: ["#FF949D", "#FFB3AE", "#EFCAB2", "#B4CA91", "#9FDAC5", "#A2ACD5;"],
    };
  }

  randomColor = (colors) => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  render() {
    const { colors } = this.state;
    const username = this.props && this.props.username;
    const userImage = this.props && this.props.userImage;

    let nameList =
      username && username.split(/ (.*)/)
        ? username && username.split(/ (.*)/)
        : "";
        console.log(nameList)
    let nameInitials =
      nameList && nameList[1]
        ? nameList && nameList[0][0] + nameList && nameList[1][0]
        : nameList && nameList[0][0];

    return (
      <>
        {userImage ? (
          <div>
            <img
              src={userImage}
              alt={userImage}
              className="CustomUser"
              style={{ padding: 0 }}
            />
          </div>
        ) : (
          <div
            className="CustomUser"
            style={{ background: `${this.randomColor(colors)}` }}
          >
            {nameInitials}
          </div>
        )}
      </>
    );
  }
}

export default CustomUser;
