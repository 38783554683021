import React, {Component} from 'react';
import Header from './Header';
import bannerTrophy from "../../images/bannerTrophy.png"
import QuizOfDay from './QuizOfDay';
import ClassQuiz from './ClassQuiz';
import Projects from './Projects';
import {baseUrl} from "../../config";
import Loader from "../UI/Loader/Loader"

class Winners extends Component {
    constructor(){
        super();
        this.state = {
            tabData : [
                {tab:"QUIZ OF THE DAY"},
                {tab:"CLASS QUIZ"},
                {tab:"PROJECT"},
            ],
            prizeDetails1 : {
                para1:"1st Prize Winners",
                para2:"Samsung Tablet",
                // image:prize1,
            },
            results : {},
            currentIndex:0,
            isLoader:true,
        }
    }

    componentDidMount() {
        this.fetchApiDetails();
    }

    switchTab = (i) => {
        this.setState({currentIndex:i})
    }

    fetchApiDetails = () => {
        const {results} = this.state;
        let payload = {}
        fetch(`${baseUrl}/v2/course/codingWeek/get`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-access-token" : localStorage.getItem("TOKEN")
            },
          })
            .then((res) => res.json())
            .then((res) => {
                this.setState({results:res.data,isLoader:false},()=>{console.log(results)})
            })
            .catch((err) => console.log(err.message));
    }

    render() {
        const {tabData, prizeDetails1,results,currentIndex,isLoader} = this.state;
        return (
            isLoader ? 
            <Loader /> :
            <div className="Winners">
                <Header />
                <img src={bannerTrophy} alt="banner" className="Winners__Banner" />
                <div className="Winners__Tab">
                    {tabData.map((data,index)=>{
                        return(
                            <div 
                                style={index === currentIndex ? {borderBottom:"2px solid #EA9200"} : {color:"#ffffff"}}
                                className="Winners__Tab--Data" 
                                // onClick={()=>{this.switchTab(index)}}
                                key={index}
                            >{data.tab}</div>
                        )
                    })}
                </div>
                <div className="Winners__Content">
                    {currentIndex === 0 ? 
                        results.quizOfDay && results.quizOfDay.map((data,index)=>{ 
                            return (<QuizOfDay prizeDetails={prizeDetails1} key={index} studentName={data}/> )
                        })
                        :currentIndex === 1 ? <ClassQuiz />: <Projects />}
                </div>
            </div>
        )
    }
}

export default Winners;