import React, { Component } from 'react';
import ImageComponent from './MiniComponents/ImageComponent';
import img1 from "../images/img1.svg";
import img2 from "../images/img2.svg";
import img3 from "../images/img3.svg";
import img4 from "../images/img4.svg";
import img5 from "../images/img5.svg";

class Awards extends Component {
    render(){
        return (
            <div className="Awards">
                <div className="Awards__Head">Awards & Prizes</div>
                <div className="Awards__Body">
                    <div className="Awards__Body--Para">Plenty of prizes to be won throughout the week. Participate & win!</div>
                    <div className="Awards__Body--Row1">
                        <ImageComponent para={"Samsung Tablet"} image={img1} />
                        <ImageComponent para={"JBL Earphones"} image={img2} />
                    </div>
                    <div className="Awards__Body--Row2">
                        <ImageComponent para={"Amazon Vouchers"} image={img3} />
                        <ImageComponent para={"Participation Certificates"} image={img4} />
                        <ImageComponent para={"Coding Courses"} image={img5} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Awards;