import React, { Component } from "react";
import Header from "./Header";
import quizBg from "../../images/quizBg.png";
import download from "../../images/download.svg";
import img2 from "../../images/checkBg.png";
import check from "../../images/icon.svg";
import QuizPic from "../../images/QuizCoding.jpeg";
class QuizForm extends Component {
  constructor() {
    super();
    this.state = {
      quizSuccess: false,
      answer: "",
      bgColor: "#e5e5e5",
      buttonEnable: true,
      submitDone: false,
    };
  }

  submitQuiz = () => {
    this.setState({ quizSuccess: !this.state.quizSuccess }, () => {
      if (!this.state.quizSuccess) {
        this.setState({ submitDone: true });
      }
      if(this.state.quizSuccess){
            localStorage.setItem("QUIZDONE2","true")
            let payload = {
                name:localStorage.getItem("NAME"),
                userId:localStorage.getItem("USERID"),
                number:localStorage.getItem("NUMBER"),
                orgId:localStorage.getItem("ORGID"),
                email:localStorage.getItem("EMAIL"),
                grade:localStorage.getItem("SELECTCLASS"),
                answer: this.state.answer,
            }

            fetch(`https://hooks.zapier.com/hooks/catch/4460305/ochmhql/  `, {
                method: "POST",
                body: JSON.stringify(payload),
            })
                .then((res) => res.json())
                .then((res) => {
                    console.log(res.message)
                })
                .catch((err) => console.log(err.message));
        }
    });
  };

  giveAnswer = (e) => {
    this.setState(
      { answer: e.target.value, bgColor: "#529fa4", buttonEnable: false },
      () => {
        if (this.state.answer === "") {
          this.setState({ bgColor: "#e5e5e5", buttonEnable: true });
        }
      }
    );
  };

  downloadFile = () => {
    fetch(
      "https://cors-anywhere.herokuapp.com/https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/Dec20/WhatsApp+Image+2020-12-17+at+12.31.36+PM.jpeg",
      {
        method: "GET",
        headers: {
          "Content-Type": "image/jpeg",
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `QuizCoding.jpeg`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  render() {
    const {
      quizSuccess,
      answer,
      bgColor,
      buttonEnable,
      submitDone,
    } = this.state;
    return (
      <div className="QuizForm">
        <Header />
        <div className="QuizForm__Body">
          <img className="QuizForm__Body--Img" src="https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/Dec20/quizBg.svg" alt="quizBg" />
          <div className="QuizForm__Body--Question">QUESTION</div>
          <div className="QuizForm__Body--Para1">
            Let’s see how many programming terms you can find! Selected ones get
            to win Amazon Vouchers worth Rs. 1000 from us😍
          </div>
          <div className="QuizForm__Body--Para2">
            Enter your answer in the box below and separate by commas. For
            example, 1. Programming term(i), 2. Programming term(ii), 3.
            Programming term(iii) and so on.
          </div>
          <img className="QuizForm__Body--ImgBg" src={QuizPic} alt="imageBg" />
          <button
            onClick={this.downloadFile}
            className="QuizForm__Body--DownBtn"
            href="../../images/QuizCoding.png"
            // target="_blank"

            download="https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/Dec20/WhatsApp+Image+2020-12-17+at+12.31.36+PM.jpeg"
          >
            <img
              src={download}
              alt="download"
              className="QuizForm__Body--DownBtn__Img"
            />
            <label className="QuizForm__Body--DownBtn__Label">
              Download Image
            </label>
          </button>
          <div className="QuizForm__Body--Answer">ANSWER</div>
          <textarea
            onChange={this.giveAnswer}
            value={answer}
            className="QuizForm__Body--InputBox"
            rows="8"
            placeholder="Type your answer here"
          />
          {!submitDone ? (
            <button
              onClick={this.submitQuiz}
              className="QuizForm__Body--Submit"
              style={{ background: bgColor }}
              disabled={buttonEnable}
            >
              Submit Answer
            </button>
          ) : (
            <button className="QuizForm__Body--BtnDone">
              <img src={check} alt="check" />
              <label className="QuizForm__Body--BtnDone__Para">
                Submitted{" "}
              </label>
            </button>
          )}
        </div>
        {quizSuccess ? (
          <div className="RegisterModal">
            <div className="RegisterModal__Container">
              <div className="RegisterModal__Container--Header">
                <img
                  src="https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/Dec20/checkBg.svg"
                  alt="select"
                  className="RegisterModal__Container--Header__Img"
                />
              </div>
              <div className="RegisterModal__Container--Body">
                <div className="RegisterModal__Container--Body__Para1">
                  Submitted successfully!
                </div>
                <div className="RegisterModal__Container--Body__Para2">
                  You have submitted your answer to the Quiz of the Day. Results
                  will be out soon.
                </div>
              </div>
              <div className="RegisterModal__Container--Footer">
                <button
                  className="RegisterModal__Container--Footer__Btn"
                  onClick={this.submitQuiz}
                  style={{ background: "#2C7478" }}
                >
                  Okay!
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default QuizForm;
