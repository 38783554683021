import React, { Component } from "react";
import EventComponent from "./MiniComponents/EventComponent";
import ue1 from "../images/ue1.svg";
import ue2 from "../images/ue2.svg";
import ue3 from "../images/ue3.svg";

class UpcomingEvents extends Component {
  constructor() {
    super();
    this.state = {
      eventData: [
        // {
        //     image:ue1,
        //     para1:"Free Coding Classes",
        //     para2:"Registrations Open",
        //     date:"19-20",
        //     month:"Dec",
        // },
        // {
        //     image:ue2,
        //     para1:"Code and create innovative projects",
        //     para2:"Coming Soon",
        //     date:"21-23",
        //     month:"Dec",
        // },
        {
          image: ue3,
          para1: "Quiz Wizard",
          para2: "Coming Soon",
          date: "22",
          month: "Dec",
        },
      ],
    };
  }
  render() {
    const { eventData } = this.state;
    return (
      <div className="UpcomingEvents">
        <div className="UpcomingEvents__Head">Upcoming Events</div>
        <div className="UpcomingEvents__Body">
          {eventData.map((data, index) => {
            return (
              <EventComponent
                openUpModal={this.props.openUpModal}
                id={index}
                key={index}
                data={data}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default UpcomingEvents;
