import React from 'react';
import { Component } from 'react';
import PrizeComponent from '../MiniComponents/PrizeComponent';
import WinnerName from '../MiniComponents/WinnerName';

class QuizOfDay extends Component {
    constructor(){
        super();
        this.state = {
            setlength:3,
            maxlength:"",
            rank1:[],
            rank2:[],
        }
    }
    
    clickSeeMore = (num) => {
        this.setState({setlength:num});
    }

    render(){
        const {studentName} = this.props;
        const {setlength} =this.state;
        const maxlength = studentName.winners.length;
        return(
            <div className="QuizOfDay">
                <PrizeComponent label={studentName.label} prize={studentName.prize} rank={studentName.rank} />
                <div className="QuizOfDay__Names">
                    {studentName.winners.length > 3 ?   
                        studentName.winners && studentName.winners.slice(0,setlength).map((data,index)=>{
                                return(<WinnerName key={index} data={data} />)
                            }
                        ) : 
                        studentName.winners && studentName.winners.map((data,index)=>{
                                return(<WinnerName key={index} data={data} />)
                            }
                        )
                    }
                </div>
                {studentName.winners.length > 3 ?
                <div onClick={()=>{
                    setlength === 3 ? 
                        this.clickSeeMore(maxlength):
                        this.clickSeeMore(3);
                    }} 
                    className="QuizOfDay__Footer">
                    {setlength !== 3?"See Less":"See More"}
                </div> : ""}
            </div>
        )
    }
}

export default QuizOfDay;