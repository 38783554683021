import React, { Component } from 'react';
import Content1 from './Content1';
import Content2 from './Content2';
import close from "../../images/close.svg";
import check from "../../images/icon.svg";

class UpComingModal extends Component {
    openRegister = () => {
        this.props.openUpModal();
        this.props.openRegister();
    }

    render(){
        const {dataArray,seletedArray,regDone,selectClass} = this.props;
        const currentIndex = selectClass === "Class 6 - Class 8" ? 0 : 1;
        return (
            <div className="UpComingModal">
                <div className="UpComingModal__Container">
                    <div className="UpComingModal__Container--Header">
                        <img src={dataArray.image} alt="select" className="UpComingModal__Container--Header__Img" />
                        <img src={close} onClick={this.props.openUpModal} alt="close" className="RegisterModal__Container--Header__Close" />
                    </div>
                    {seletedArray === 0?
                        <div className="UpComingModal__Container--Body">
                            <div className="UpComingModal__Container--Body__Para1">{dataArray.heading}</div>
                            <Content1 head={dataArray.data1.head} para={dataArray.data1.para} />
                            <div className="UpComingModal__Container--Body__Div">
                                <div className="UpComingModal__Container--Body__Div--Head">{dataArray.data2.head}</div>
                                <div className="UpComingModal__Container--Body__Div--Para"><span className="Bold">{dataArray.data2.timeSlot[currentIndex].date1}</span>{dataArray.data2.timeSlot[currentIndex].time1}</div>
                                <div className="UpComingModal__Container--Body__Div--Para"><span className="Bold">{dataArray.data2.timeSlot[currentIndex].date2}</span>{dataArray.data2.timeSlot[currentIndex].time2}</div>
                            </div>
                            <Content2 head={dataArray.data3.head} dataArr={dataArray.data3.points} />
                        </div>
                    :
                        <div className="UpComingModal__Container--Body" style={{paddingBottom:"6rem"}}>
                            <div className="UpComingModal__Container--Body__Para1">{dataArray.heading}</div>
                            <Content1 head={dataArray.data1.head} para={dataArray.data1.para} />
                            <Content1 head={dataArray.data2.head} para={dataArray.data2.para} />
                            <Content2 head={dataArray.data3.head} dataArr={dataArray.data3.points} />
                        </div>
                    }
                    {seletedArray !== 0 ?
                        ""
                    :<div className="UpComingModal__Container--Footer">
                        {/* {!regDone ?
                            <button
                                className="UpComingModal__Container--Footer__Btn"
                                onClick={this.openRegister}
                                style={{background:"#2C7478"}}
                            >
                                Register Now
                            </button>
                            :
                            <button className="UpComingModal__Container--Footer__BtnDone">
                                <img src={check} alt="check" />
                                <label className="UpComingModal__Container--Footer__BtnDone--Para">Registered</label>
                            </button>
                        } */}

                            <button className="UpComingModal__Container--Footer__BtnDone">
                                <img src={check} alt="check" />
                                <label className="UpComingModal__Container--Footer__BtnDone--Para">Registered</label>
                            </button>
                    </div>}
                </div>
            </div>
        )
    }
}

export default UpComingModal;