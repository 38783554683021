import React, { Component } from "react";
import Home from "./components/Home";
import ScrollToTop from "./ScrollToTop";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import QuizForm from "./components/QuizForm/QuizForm";
import ProjectForm from "./components/Day5/ProjectForm";
import Winners from "./components/Winners/Winners";

class App extends Component {
  constructor() {
    super();
    this.state = { classSelected: false };
  }

  componentDidMount() {
    this.setState({ classSelected: true });
    localStorage.setItem("QUIZDONE", "");
    localStorage.setItem("BGCOLOR1", "");
    localStorage.setItem("BGCOLOR2", "");
    localStorage.setItem("COLOR1", "");
    localStorage.setItem("COLOR2", "");
    localStorage.setItem("SELECTCLASS", "");
    localStorage.setItem("REGDONE", "");
    localStorage.setItem("QUIZDONE2", "");
    localStorage.setItem("SELECTTIME", "");

    let authToken =
      this.getQuery()["token"] ||
      "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MjY3MzI0NCwib3JnSWQiOjMzMTksIm5hbWUiOiJFS0FNQ0MiLCJlbWFpbCI6ImVrYW1jY0B5YWhvby5jby5pbiIsIm1vYmlsZSI6IjkxOTQxNjE0MTA0MiIsInR5cGUiOjMsImlhdCI6MTYwODgxODM3MiwiZXhwIjoxNjA5NDIzMTcyfQ.Wrki0TIp_doA38wYfdgMHZDtvXxRntykpMswlaIOtSs6aq42w9OBe_bYl_ifTJCf";
    localStorage.setItem("TOKEN", authToken);
    let setToken = localStorage.getItem("TOKEN");
    let authTokenData = this.parseToken(setToken);

    localStorage.setItem("NAME", authTokenData.name);
    localStorage.setItem("USERID", authTokenData.id);
    localStorage.setItem("NUMBER", authTokenData.mobile);
    localStorage.setItem("ORGID", authTokenData.orgId);
    localStorage.setItem("EMAIL", authTokenData.email);
  }

  parseToken = (token) => {
    return JSON.parse(
      token.replace(/^[^]+\.([^]+)\.[^]+$/, (token, payload) =>
        Buffer.from(payload, "base64").toString("ascii")
      )
    );
  };

  getQuery = () => {
    let b = window.location.search
      .slice(1)
      .split("&")
      .map((qStr) => qStr.split("="))
      .reduce((acc, inc) => {
        acc[inc[0]] = inc[1];
        return acc;
      }, {});
    return b;
  };

  openModal = () => {
    this.setState({ classSelected: !this.state.classSelected });
  };

  render() {
    const { classSelected } = this.state;
    return (
      <div className="App">
        <BrowserRouter>
          <ScrollToTop>
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <Home
                    classSelected={classSelected}
                    openModal={this.openModal}
                  />
                )}
              />
              <Route exact path="/quiz" component={QuizForm} />
              <Route exact path="/Project" component={ProjectForm} />
              <Route exact path="/winners" component={Winners} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
