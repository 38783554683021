import React,{ Component } from 'react';

class Projects extends Component {
    render() {
        return (
            <div className="Projects">Coming Soon</div>
        )
    }
}

export default Projects;