import React,{ Component } from 'react';

class Content2 extends Component {
    render(){
        const {head,dataArr} = this.props;
        return (
            <div className="Content1">
                <div className="Content1__Head">{head}</div>
                {dataArr.map((data,index)=>{
                    return(<div className="Content1__Para">{data}</div>)
                })}
            </div>
        )
    }
}

export default Content2;