import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import coding from "../images/coding1.svg";
import trophy from "../images/trophy.svg";
import check from "../images/icon.svg";

class ProjectDay extends Component {
    render(){
        const isQuizDone = localStorage.getItem("QUIZDONE2");

        return (
            <div className="QuizDay">
                <div className="QuizDay__Head">Today’s Event</div>
                <div className="QuizDay__Main">
                    <div className="QuizDay__Body">
                        <div className="QuizDay__Body--Col1">
                            <div className="QuizDay__Body--Col1__Para1">Code and create <br/> innovative projects</div>
                            <div className="QuizDay__Body--Col1__Para2">21 Dec - 23 Dec</div>
                            {isQuizDone === "" ?
                            <button onClick={()=>{this.props.history.push('/Project')}} className="QuizDay__Body--Col1__Btn">Participate Now</button>
                            :<button className="QuizDay__Body--Col1__BtnDone">
                                <img src={check} alt="alt" className="QuizDay__Body--Col1__BtnDone--Img" />
                                <label className="QuizDay__Body--Col1__BtnDone--Para">Attempted</label>
                            </button>
                            }
                            <div className="RegisterNow__Body--Col1__Warning">* Hurry up... Submission on 23rd Dec</div>
                        </div>
                        <div className="QuizDay__Body--Col2">
                            <img src={coding} alt="book" className="QuizDay__Body--Col2__Img" />
                        </div>
                    </div>
                    <div className="RegisterNow__Footer">
                    <img className="RegisterNow__Footer--Img" src={trophy} alt="trophy" />
                    <div className="RegisterNow__Footer--Para">You can win a samsung tablet, amazon vouchers, coding courses & participation certificate</div>
                </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ProjectDay);