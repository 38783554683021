import React, { Component } from "react";
import Header from "./Header";
import screen from "../../images/PairProgrammingCuate1.svg";
import download from "../../images/download.svg";

import check from "../../images/icon.svg";
import QuizPic from "../../images/QuizCoding.jpeg";
class ProjectForm extends Component {
  constructor() {
    super();
    this.state = {
      quizSuccess: false,
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      answer5: "",
      bgColor: "#e5e5e5",
      buttonEnable: true,
      submitDone: false,
    };
  }

  submitQuiz = () => {
    this.setState({ quizSuccess: !this.state.quizSuccess }, () => {
      if (!this.state.quizSuccess) {
        this.setState({ submitDone: true });
      }
      if (this.state.quizSuccess) {
        console.log(
          this.state.answer1 +
            " " +
            this.state.answer2 +
            " " +
            this.state.answer3 +
            " " +
            this.state.answer4 +
            " " +
            this.state.answer5 +
            " "
        );
        // localStorage.setItem("QUIZDONE2","true")
        // let payload = {
        //     name:localStorage.getItem("NAME"),
        //     userId:localStorage.getItem("USERID"),
        //     number:localStorage.getItem("NUMBER"),
        //     orgId:localStorage.getItem("ORGID"),
        //     email:localStorage.getItem("EMAIL"),
        //     grade:localStorage.getItem("SELECTCLASS"),
        //     answer: this.state.answer,
        // }

        // fetch(`https://hooks.zapier.com/hooks/catch/4460305/ochmhql/  `, {
        //     method: "POST",
        //     body: JSON.stringify(payload),
        // })
        //     .then((res) => res.json())
        //     .then((res) => {
        //         console.log(res.message)
        //     })
        //     .catch((err) => console.log(err.message));
      }
    });
  };

  giveAnswer = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        bgColor: "#529fa4",
        buttonEnable: false,
      },
      () => {
        if (this.state.answer === "") {
          this.setState({ bgColor: "#e5e5e5", buttonEnable: true });
        }
      }
    );
  };

  downloadFile = (fileLink) => {
    fetch(`https://cors-anywhere.herokuapp.com/${fileLink}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `manual.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  render() {
    let selectClass = localStorage.getItem("SELECTCLASS");
    console.log(selectClass);
    const {
      quizSuccess,
      answer,
      bgColor,
      buttonEnable,
      submitDone,
    } = this.state;
    if (selectClass === "Class 9 - Class 12") {
      return (
        <div className="QuizForm">
          <Header />

          <div className="QuizForm__Body">
            <img className="QuizForm__Body--Img" src={screen} alt="quizBg" />
            <div className="QuizForm__Body--Question">QUESTION</div>
            <div className="QuizForm__Body--Para1">
              In this task, you will have to solve a set of projects. The
              challenge is to find the ‘bug’ in them and make them functional as
              per the desired output. 
              <br/>
              Click here to check the projects you have
              to solve. Please submit your answers in the attached form itself.
              The last day to submit the projects is Wednesday, 23rd December.
            </div>
            <button
              className="QuizForm__Body--Submit"
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSdVsHk-jjO4pzur3cJzi2PY1Vd2KfxSd083MofIPshXlJQbJA/viewform"
                )
              }
            >
              Please open this Google Form
            </button>
            <div className="QuizForm__Body--Para2">
              Download the PDF to know how to work on the debugging questions on
              Google Colab
            </div>
            {/* <img className="QuizForm__Body--ImgBg" src={QuizPic} alt="imageBg" /> */}
            <button
              onClick={() =>
                window.open(
                  "https://drive.google.com/file/d/1bH0y48P7l4bEvAhuKv1DabW27T7VgeXs/view?usp=sharing"
                )
              }
              className="QuizForm__Body--DownBtn"
              href="../../images/QuizCoding.png"
              // target="_blank"

              download="https://drive.google.com/file/d/1bH0y48P7l4bEvAhuKv1DabW27T7VgeXs/view?usp=sharing"
            >
              <img
                src={download}
                alt="download"
                className="QuizForm__Body--DownBtn__Img"
              />
              <label className="QuizForm__Body--DownBtn__Label">
                Download PDF
              </label>
            </button>
          </div>

          {/* {quizSuccess ? (
          <div className="RegisterModal">
            <div className="RegisterModal__Container">
              <div className="RegisterModal__Container--Header">
                <img
                  src="https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/Dec20/checkBg.svg"
                  alt="select"
                  className="RegisterModal__Container--Header__Img"
                />
              </div>
              <div className="RegisterModal__Container--Body">
                <div className="RegisterModal__Container--Body__Para1">
                  Submitted successfully!
                </div>
                <div className="RegisterModal__Container--Body__Para2">
                  You have submitted your answer to the Quiz of the Day. Results
                  will be out soon.
                </div>
              </div>
              <div className="RegisterModal__Container--Footer">
                <button
                  className="RegisterModal__Container--Footer__Btn"
                  onClick={this.submitQuiz}
                  style={{ background: "#2C7478" }}
                >
                  Okay!
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )} */}
        </div>
      );
    } else if (selectClass === "Class 6 - Class 8") {
      return (
        <div className="QuizForm">
          <Header />

          <div className="QuizForm__Body">
            <img className="QuizForm__Body--Img" src={screen} alt="quizBg" />
            <div className="QuizForm__Body--Question">QUESTION</div>
            <div className="QuizForm__Body--Para1">
              In this task, you will have to solve a set of projects. The
              challenge is to find the ‘bug’ in them and make them functional as
              per the desired output. In order to do that, you will need to use
              the ‘Remix’ option on Scratch, which lets you work on someone
              else’s project by copying them in your account.
            </div>
            <div className="QuizForm__Body--Para2">
              Click here to check the projects you have to solve. Please submit
              your answers in the attached form itself. 
              <br/>
              The last day to submit
              the projects is Wednesday, 23rd December.
            </div>

            <button
              className="QuizForm__Body--Submit"
              onClick={() =>
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSeKVHBElNqg8m6wqMDoVqdzXGoXbQxyAWKItg-tf-dukDfVpw/viewform"
                )
              }
            >
              Please open this Google Form
            </button>
            <div className="QuizForm__Body--Para2">
              Download the PDF to know how to use Scratch.
            </div>

            {/* <img className="QuizForm__Body--ImgBg" src={QuizPic} alt="imageBg" /> */}
            <button
              onClick={() =>
                window.open(
                  "https://drive.google.com/file/d/1bH0y48P7l4bEvAhuKv1DabW27T7VgeXs/view?usp=sharing"
                )
              }
              className="QuizForm__Body--DownBtn"
              // href="../../images/QuizCoding.png"
              // target="_blank"

              // download="https://drive.google.com/file/d/1bH0y48P7l4bEvAhuKv1DabW27T7VgeXs/view?usp=sharing"
            >
              <img
                src={download}
                alt="download"
                className="QuizForm__Body--DownBtn__Img"
              />
              <label className="QuizForm__Body--DownBtn__Label">
                Download PDF
              </label>
            </button>
          </div>

          {/* {quizSuccess ? (
        <div className="RegisterModal">
          <div className="RegisterModal__Container">
            <div className="RegisterModal__Container--Header">
              <img
                src="https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/Dec20/checkBg.svg"
                alt="select"
                className="RegisterModal__Container--Header__Img"
              />
            </div>
            <div className="RegisterModal__Container--Body">
              <div className="RegisterModal__Container--Body__Para1">
                Submitted successfully!
              </div>
              <div className="RegisterModal__Container--Body__Para2">
                You have submitted your answer to the Quiz of the Day. Results
                will be out soon.
              </div>
            </div>
            <div className="RegisterModal__Container--Footer">
              <button
                className="RegisterModal__Container--Footer__Btn"
                onClick={this.submitQuiz}
                style={{ background: "#2C7478" }}
              >
                Okay!
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )} */}
        </div>
      );
    }
  }
}

export default ProjectForm;
