import React,{ Component } from 'react';

class Content1 extends Component {
    render(){
        const {head,para} = this.props;
        return (
            <div className="Content1">
                <div className="Content1__Head">{head}</div>
                <div className="Content1__Para">{para}</div>
            </div>
        )
    }
}

export default Content1;