import React from 'react';
import { Component } from 'react';

class ClassQuiz extends Component {
    render() {
        return (
            <div className="ClassQuiz">Coming Soon</div>
        )
    }
}

export default ClassQuiz;