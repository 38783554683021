import React, {Component} from 'react';
import back from "../../images/back.svg";
import { withRouter } from "react-router-dom";

class Header extends Component {
    goToHome = () => {
        this.props.history.push('/');
        localStorage.setItem("QUIZDONE", "true");
    }
    render(){
        return(
            <div className="Header">
                <img className="Header__Back" onClick={this.goToHome} src={back} alt="back" />
                <div className="Header__Head">Quiz of the day</div>
            </div>
        )
    }
}

export default withRouter(Header);