import React from 'react';
import { Component } from 'react';
import person from "../../images/person.png";
import CustomUser from '../CustomUser/CustomUser';

class WinnerName extends Component {
    render(){
        const {data} = this.props; 
        return(
            <div className="WinnerName">
                {/* <img src={person} alt="person" className="WinnerName__Img" /> */}
                <CustomUser username={data && data.name} userImage={data && data.imageUrl} />
                <div className="WinnerName__Content">
                    <div className="WinnerName__Content--Para1">{data && data.name}</div>
                    <div className="WinnerName__Content--Para2">{data && data.coachingName}</div>
                </div>
            </div>
        )
    }
}

export default WinnerName;