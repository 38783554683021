import React, { Component } from 'react';

class ImageComponent extends Component {
    render(){
        const {para,image} = this.props;
        return (
            <div className="ImageComponent">
                <img alt="images" src={image} />
                <div className="ImageComponent__Para">{para}</div>
            </div>
        )
    }
}

export default ImageComponent;