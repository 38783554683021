import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import trophy from "../images/bigTrophy.png";
import gift from "../images/gift.gif";
import check from "../images/icon.svg";

class PreviousWinner extends Component {
    render(){
        // const isQuizDone = localStorage.getItem("QUIZDONE2");
        return (
            <div className="PreviousWinner">
                <div className="PreviousWinner__Head">Previous Winners</div>
                <div className="PreviousWinner__Main">
                    <div className="PreviousWinner__Body">
                        <div className="PreviousWinner__Body--Col1">
                            <div className="PreviousWinner__Body--Col1__Para1">Results Out!</div>
                            <div className="PreviousWinner__Body--Col1__Para2">See the winners of past events of the week</div>
                            {/* {isQuizDone === "" ? */}
                            <button onClick={()=>{this.props.history.push('/winners')}} className="PreviousWinner__Body--Col1__Btn">Check Now</button>
                            {/* :<button className="PreviousWinner__Body--Col1__BtnDone">
                                <img src={check} alt="alt" className="PreviousWinner__Body--Col1__BtnDone--Img" />
                                <label className="PreviousWinner__Body--Col1__BtnDone--Para">Attempted</label>
                            </button>} */}
                        </div>
                        <div className="PreviousWinner__Body--Col2">
                            <img src={trophy} alt="book" className="PreviousWinner__Body--Col2__Img" />
                        </div>
                    </div>
                    {/* <div className="PreviousWinner__Footer">
                        <img src={gift} alt="gift" className="PreviousWinner__Footer--Img" />
                        <div className="PreviousWinner__Footer--Para">You have a chance of winning amazon vouchers worth ₹500</div>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default withRouter(PreviousWinner);